import React from 'react';
import moment from 'moment';
import _ from 'lodash';

export default class Clock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: '00:00:00',
    };
  }

  componentDidMount() {
    this.tick();
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  // eslint-disable-next-line class-methods-use-this
  formatScore(score) {
    const time = moment.duration(score, 'minutes');
    let duration = time.as('milliseconds');
    let prefix = '+';

    if (duration < 0) {
      prefix = '-';
      duration *= -1;
    }

    if (duration === 0) {
      prefix = '';
    }

    return `${prefix} ${moment.utc(duration).format('HH:mm:ss')}`;
  }

  tick() {
    const { scoreboard, uid } = this.props;
    const yourScore = _.get(scoreboard, `[${uid}]`, 0);
    // console.log(yourScore);
    // const yourScore = -15;

    const startTime = moment('16:00:00', 'HH:mm:ss');
    const timePassed = moment().diff(startTime);
    this.setState({
      time: moment(timePassed + (yourScore * 60000)).utc().format('HH:mm:ss'),
      // time: moment().format('HH:mm:ss')
    });
  }

  render() {
    const { time } = this.state;
    const { color, scoreboard, uid } = this.props;

    const yourScore = _.get(scoreboard, `[${uid}]`, null);
    let score = null;

    if (yourScore !== null && yourScore !== 0) {
      score = this.formatScore(yourScore);
    }

    return (
      <div className="clock" style={{ backgroundColor: color }}>
        { time }
        { score && <div className="extra-time">{ score }</div> }
      </div>
    );
  }
}
