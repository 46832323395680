/* eslint-disable class-methods-use-this */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Map from './Map';
import Hints from './Hints';

const messagebird = require('messagebird')('6gHkp0tvLv1ku9b0qxSEaaEA7');

export default class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments: [],
      upsertAssignment: null,
      answers: [],
      logs: [],
      teams: [],
      scoreboard: {},
      message: '',
      phones: [],
    };

    this.updateFromText = this.updateFromText.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.saveAssignment = this.saveAssignment.bind(this);
    this.releaseAssignment = this.releaseAssignment.bind(this);
    this.removeAssignment = this.removeAssignment.bind(this);
    this.removeProgress = this.removeProgress.bind(this);
    this.sendBulk = this.sendBulk.bind(this);
    this.updateSMSMessage = this.updateSMSMessage.bind(this);
    this.finish = this.finish.bind(this);
  }

  componentDidMount() {
    this.loadAssignments();
    this.loadAnswers();
    this.loadScores();

    this.loadTeams();
    this.loadLogs();
    this.loadPhones();
  }

  loadAssignments() {
    const { database } = this.props;

    database.collection('assignments').onSnapshot((querySnapshot) => {
      const assignments = [];
      querySnapshot.forEach((doc) => {
        const assignment = doc.data();
        assignment.id = doc.id;
        assignments.push(assignment);
      });
      this.setState({
        assignments,
      });
    });
  }

  loadAnswers() {
    const { database } = this.props;

    database.collection('answers').onSnapshot((querySnapshot) => {
      const answers = [];
      querySnapshot.forEach((doc) => {
        const answer = doc.data();
        answer.id = doc.id;
        answers.push(answer);
      });
      this.setState({
        answers,
      });
    });
  }

  loadLogs() {
    const { database } = this.props;

    database.collection('logs').onSnapshot((querySnapshot) => {
      let logs = [];
      querySnapshot.forEach((doc) => {
        const log = doc.data();
        log.id = doc.id;
        logs.push(log);
      });
      logs = _.orderBy(logs, 'timestamp', 'desc');
      this.setState({
        logs,
      });
    });
  }

  loadTeams() {
    const { database } = this.props;

    database.collection('teams').onSnapshot((querySnapshot) => {
      const teams = [];
      querySnapshot.forEach((doc) => {
        const team = doc.data();
        team.id = doc.id;
        teams.push(team);
      });
      this.setState({
        teams,
      });
    });
  }

  loadScores() {
    const { database } = this.props;

    database.collection('scores').onSnapshot((querySnapshot) => {
      let scores = [];
      querySnapshot.forEach((doc) => {
        scores.push(doc.data());
      });
      scores = _.groupBy(scores, score => score.teamId);
      const scoreboard = {};
      _.map(scores, (score) => {
        scoreboard[score[0].teamId] = _.sumBy(score, 'minutes');
      });
      this.setState({
        scoreboard,
      });
    });
  }

  loadPhones() {
    const { database } = this.props;

    database.collection('phones').onSnapshot((querySnapshot) => {
      const phones = [];
      querySnapshot.forEach((doc) => {
        const phone = doc.data();
        phone.id = doc.id;
        phones.push(phone);
      });
      this.setState({
        phones,
      });
    });
  }

  sendSMS() {
    const { phones } = this.state;
    console.log(phones);
    return;
    const params = {
      originator: 'THFT',
      recipients: [
        '0031610774552‬',
      ],
      body: 'Hoi, dit is een testbericht.',
    };
    messagebird.messages.create(params, (err, response) => {
      if (err) {
        return console.log("ERROR", err);
      }
      return console.log(response);
    });
  }

  requestHint() {
    const { functions } = this.props;
    const requestHint = functions.httpsCallable('requestHint');

    requestHint({ assignmentId: null, lat: null, lng: null }).then((result) => {
      console.log(result);
    });
  }

  sendBulk() {
    const { message, phones } = this.state;
    const recipients = _.map(phones, 'number');
    const r = confirm(`Weet je zeker dat je deze SMS wil versturen naar ${recipients.length} nummers?`);
    console.log(recipients);
    if (!r) {
      return;
    }
    // return;
    if (!message || message === '') { return; }
    const params = {
      originator: 'THFT',
      recipients,
      body: message,
    };
    messagebird.messages.create(params, (err, response) => {
      if (err) {
        return alert(err);
      }
      this.setState({ message: '' });
      return console.log(response);
    });
  }


  clickEditAssignment(assignment) {
    this.setState({
      upsertAssignment: assignment,
    });
  }

  createAssignment() {
    this.setState({
      upsertAssignment: {
        id: Math.random().toString(36).substr(2, 22) + Math.random().toString(36).substr(2, 22),
        teams: [],
        answer: null,
        image: '',
        order: 0,
        question: '',
        title: '',
        trackId: '',
        solved: [],
      },
    });
  }

  updateFromText(event) {
    const { upsertAssignment } = { ...this.state };
    const currentState = upsertAssignment;
    let { id, value } = event.target;
    if (id === 'order') {
      value = parseInt(value);
    }
    currentState[id] = value;
    this.setState({ upsertAssignment: currentState });
  }

  updateAnswer(event) {
    const { database } = this.props;
    const { upsertAssignment } = { ...this.state };
    const currentState = upsertAssignment;
    const { value, id } = event.target;
    if (value) {
      currentState[id] = database.doc(`answers/${value}`);
    } else {
      currentState[id] = null;
    }
    this.setState({ upsertAssignment: currentState });
  }

  saveAssignment() {
    const { database } = this.props;
    const { upsertAssignment } = this.state;
    const id = _.get(upsertAssignment, 'id');
    if (!id) { return; }

    const ref = database.collection('assignments').doc(id);
    ref.set(upsertAssignment);
  }

  releaseAssignment() {
    const { database } = this.props;
    const { upsertAssignment, teams } = this.state;
    database.collection('/assignments').doc(_.get(upsertAssignment, 'id')).update({
      teams: _.map(teams, 'id'),
    });
  }

  removeAssignment() {
    const { database } = this.props;
    const { upsertAssignment } = this.state;
    database.collection('/assignments').doc(_.get(upsertAssignment, 'id')).update({
      teams: [],
    });
  }

  removeProgress() {
    const { database } = this.props;
    const { upsertAssignment } = this.state;
    database.collection('/assignments').doc(_.get(upsertAssignment, 'id')).update({
      solved: [],
    });
  }

  updateSMSMessage(event) {
    const { value } = event.target;
    this.setState({ message: value });
  }

  finish(teamId) {
    const { database } = this.props;
    console.log(`Finish: ${teamId}`);
    database.collection('/teams').doc(teamId).update({
      finished: Date.now(),
    });
  }

  render() {
    const {
      assignments,
      upsertAssignment,
      answers,
      logs,
      teams,
      phones,
      scoreboard,
      message,
    } = this.state;
    const { tracks, database } = this.props;
    const orderedAssignments = _.sortBy(assignments, ['trackId', 'order']);

    // SET ASSIGNMENTS
    if (window.location.search === '?assignments') {
      document.title = 'THFT/OPDRACHTEN';
      return (
        <div className="admin">
          <div className="assignments">
            {_.map(orderedAssignments, (assignment) => {
              const track = _.find(tracks, ['id', assignment.trackId]);
              return (
                <div key={assignment.id} className="assignment" onClick={() => this.clickEditAssignment(assignment)}>
                  <div className="order" style={{ backgroundColor: _.get(track, 'color') }}><FontAwesomeIcon icon={_.get(track, 'icon', 'faDotCircle')} /></div>
                  {`[${assignment.order}] ${assignment.title}`}
                  <span style={{ float: 'right' }}>{`${assignment.teams.length} / ${assignment.solved.length}`}</span>

                </div>
              );
            })}
            <div className="assignment" onClick={() => this.createAssignment()}>
              <div className="order" style={{ backgroundColor: '#333' }}><FontAwesomeIcon icon={faPlus} /></div>
              Nieuwe opdracht toevoegen
            </div>

          </div>
          {upsertAssignment
          && (
          <div className="upsert">
            <h2>Update Assignment</h2>

            <label>Titel</label>
            <input type="text" onChange={this.updateFromText} id="title" value={_.get(upsertAssignment, 'title', '')} />

            <label>Beschrijving</label>
            <textarea onChange={this.updateFromText} id="question" value={_.get(upsertAssignment, 'question', '')} />

            <label>Puzzel</label>
            <input type="text" onChange={this.updateFromText} id="image" value={_.get(upsertAssignment, 'image', '')} />

            <img src={upsertAssignment.image} alt="" width="100%" />

            <label>Oplossing</label>
            <select onChange={this.updateAnswer} value={_.get(upsertAssignment, 'answer.id')} id="answer">
              <option value="">-- Geen oplossing --</option>

              {_.map(answers, answer => <option key={answer.id} value={answer.id}>{answer.answer}</option>,)}
            </select>

            <label>Track</label>
            <select onChange={this.updateFromText} id="trackId" value={upsertAssignment.trackId}>
              <option value="">-- Geen track --</option>
              {_.map(tracks, track => <option value={track.id} key={track.id}>{track.name}</option>)}
              <option value="bonus">Bonus-opdracht</option>
            </select>

            <label>Volgorde</label>
            <input type="text" onChange={this.updateFromText} id="order" value={upsertAssignment.order} />
            <button type="button" onClick={this.saveAssignment}>Opslaan</button>

            <label>{`Vrijgegeven: ${upsertAssignment.teams.length}`}<span style={{ float: 'right' }}>{`Opgelost: ${upsertAssignment.solved.length}`}</span></label>

            <button type="button" onClick={this.releaseAssignment}>Vrijgeven</button>
            <button type="button" onClick={this.removeAssignment}>Sluiten</button>
            <button type="button" onClick={this.removeProgress}>Verwijder voortgang</button>
          </div>
          )}
        </div>
      );
    }

    // READ LOGS
    if (window.location.search === '?logs') {
      document.title = 'THFT/LOGS';
      return (
        <div className="admin logs">
          {_.map(logs, (log) => {
            const team = _.find(teams, ['id', log.teamId]);
            const assignment = _.find(assignments, ['id', log.assignmentId]);
            let track = '-';

            if (log.trackId === 'bonus') {
              track = { name: 'Bonus' };
            } else {
              track = _.find(tracks, ['id', log.trackId]);
            }

            if (_.get(log, 'reason')) {
              track = { name: _.get(log, 'reason') };
            }

            return (
              <div key={log.id} className="log">
                {moment(log.timestamp).format('HH:mm:ss')}
                <div className="team">{_.get(team, 'name')}</div>
                <div className={log.correct ? 'givenAnswer correct' : 'givenAnswer wrong'}>{log.answer}</div>
                <div className={log.bonus < 0 ? 'givenAnswer correct' : 'givenAnswer wrong'}>{log.bonus}</div>
                <div className="assignment">{`${_.get(track, 'name')} - ${_.get(assignment, 'order')} : ${_.get(assignment, 'title')} `}</div>
              </div>
            );
          })}
        </div>
      );
    }

    // TEAMS
    if (window.location.search === '?teams') {
      document.title = 'THFT/TEAMS';
      const totalAssignments = '8';
      const totalBonus = '9';

      return (
        <div className="admin teams">
          {_.map(_.orderBy(teams, 'name'), (team) => {
            let calculated = '';
            if (team.finished) {
              const startTime = moment('10:30:00', 'HH:mm:ss');
              const timePassed = moment(_.get(team, 'finished')).diff(startTime);
              calculated = moment(timePassed + (_.get(scoreboard, team.id, 0) * 60000)).utc().format('HH:mm:ss');
            }
            const phone = _.find(phones, ['teamId', team.id]);
            const withBonus = _.filter(assignments, { trackId: 'bonus' });
            const withoutBonus = _.filter(assignments, assignment => (assignment.trackId !== 'bonus'));
            const solvedAssignments = _.filter(withoutBonus, (assignment) => {
              return _.includes(assignment.solved, team.id);
            });
            const solvedBonus = _.filter(withBonus, (assignment) => {
              return _.includes(assignment.solved, team.id);
            });
            return (
              <div key={team.id} className="team">
                <div className="team-name">{team.name}</div>
                <div className="team-phone">{_.get(phone, 'number')}</div>
                <div className="team-phone">{`${solvedAssignments.length} / ${totalAssignments} (${_.round((solvedAssignments.length / totalAssignments) * 100)}%)`}</div>
                <div className="team-phone">{`${solvedBonus.length} / ${totalBonus} (${_.round((solvedBonus.length / totalBonus) * 100)}%)`}</div>
                <div className="team-score">{_.get(scoreboard, team.id, 0)}</div>
                {!team.finished
                  && <button type="button" onClick={() => this.finish(team.id)}>Finish</button>
                }
                {team.finished
                  && <div className="team-calculated">{calculated}</div>
                }
                {team.finished
                  && <div className="team-time">{moment(team.finished).format('HH:mm:ss')}</div>
                }
              </div>
            );
          })}
        </div>
      );
    }

    // MAP
    if (window.location.search === '?map') {
      document.title = 'THFT/KAART';
      return (
        <div className="admin teams">
          <Map teams={teams} database={database} />

        </div>
      );
    }

    // HINTS
    if (window.location.search === '?hints') {
      document.title = 'THFT/HINTS';
      return (
        <div className="admin hints">
          <Hints teams={teams} phones={phones} assignments={assignments} database={database} />
        </div>
      );
    }

    document.title = 'THFT/OVERZICHT';
    return (
      <div className="admin menu">
        <a href="/?hints" target="_blank">Hint Requests</a>
        <a href="/?map" target="_blank">Kaart</a>
        <a href="/?teams" target="_blank">Teams</a>
        <a href="/?logs" target="_blank">Logs</a>
        <a href="/?assignments" target="_blank">Opdrachten</a>
        <textarea value={message} onChange={this.updateSMSMessage} placeholder="Bulk SMS Message" />
        <button type="button" onClick={this.sendBulk}>Versturen</button>
        <button type="button" onClick={() => this.requestHint().bind(this)}>Warm-up hints</button>
      </div>
    );
  }
}
