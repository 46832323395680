import React from 'react';
import moment from 'moment';
import _ from 'lodash';

export default class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: null,
    };
  }

  componentDidMount() {
    this.tick();
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    const { refresh, openDate } = this.props;
    const remaining = _.clamp(moment().diff(moment(openDate), 'second') * -1, 0, 100000);
    if (remaining < 1) {
      refresh();
    }
    this.setState({
      time: remaining,
    });
  }

  render() {
    const { time } = this.state;
    return (
      <h1 className="countdown">{time}</h1>
    );
  }
}
