/* eslint-disable react/no-danger */
import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes, faPaperPlane, faSpinner, faLightbulb } from '@fortawesome/pro-solid-svg-icons';

export default class ShowAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      answer: '',
      success: false,
      failed: false,
      showConfirmHint: false,
      showReceivedHint: false,
    };

    this.submit = this.submit.bind(this);
    this.getHint = this.getHint.bind(this);
    this.showConfirmHint = this.showConfirmHint.bind(this);
    this.hideConfirmHint = this.hideConfirmHint.bind(this);
    this.hideReceivedHint = this.hideReceivedHint.bind(this);
    this.getSubmitResult = this.getSubmitResult.bind(this);
    this.onInputUpdate = this.onInputUpdate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction.bind(this), false);
  }

  componentDidUpdate(prevProps) {
    const { assignment } = this.props;
    if (prevProps.assignment !== assignment) {
      this.clearState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction.bind(this), false);
  }

  onInputUpdate(event) {
    const answer = event.target.value.toUpperCase();
    this.setState({ answer });
  }

  getSubmitResult(result) {
    const success = _.get(result, 'data.correct', false);
    this.setState({ loading: false, success });
    if (!success) {
      this.failed();
    }
  }

  getHint() {
    const { requestHint, assignment } = this.props;
    this.setState({ showConfirmHint: false, hintLoading: true });

    requestHint(assignment.id, (result) => {
      this.setState({ showConfirmHint: false, hintLoading: false, showReceivedHint: true });
    });
  }

  showConfirmHint() {
    this.setState({ showConfirmHint: true });
  }

  hideConfirmHint() {
    this.setState({ showConfirmHint: false });
  }

  hideReceivedHint() {
    this.setState({ showReceivedHint: false });
  }

  failed() {
    this.setState({ failed: true }, () => {
      setTimeout(() => { this.setState({ failed: false }); }, 5000);
    });
  }

  clearState() {
    this.setState({
      success: false,
      loading: false,
      answer: '',
      failed: false,
    });
  }

  submit() {
    const { submit, assignment } = this.props;
    const { answer } = this.state;
    submit(answer, assignment.id, this.getSubmitResult);

    this.setState({ loading: true, failed: false });
  }

  escFunction(event) {
    const { close } = this.props;
    if (event.keyCode === 27) {
      close(null);
    }
    if (event.keyCode === 13) {
      this.submit();
    }
  }

  render() {
    const {
      loading, answer, success, failed, showConfirmHint, hintLoading, showReceivedHint,
    } = this.state;
    const { assignment, close } = this.props;
    let className = 'assignment-full';

    if (!assignment) {
      return null;
    }

    if (success) {
      className += ' success';
    }

    if (failed) {
      className += ' failed';
    }

    if (_.get(assignment, 'trackId') === 'bonus') {
      className += ' bonus';
    }

    return (
      <div className={className}>
        <FontAwesomeIcon icon={faTimes} className="close" onClick={() => close(null)} />

        <img src={assignment.image} alt={assignment.title} className="image" />
        <div className="text">
          <h1>{assignment.title}</h1>
          <div className="question" dangerouslySetInnerHTML={{ __html: assignment.question }} />
        </div>
        {_.get(assignment, 'answer', null) && (
          <div>
            <div className="direction">Je hebt de locatie nodig om het antwoord te achterhalen. Weet jij het woord dat we zoeken?</div>
            <input type="text" className="answerfield" value={answer} onChange={this.onInputUpdate} />
            <button type="button" className="submit" disabled={loading}>
              <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} spin={loading} onClick={this.submit} />
            </button>
            {
              _.get(assignment, 'trackId') !== 'bonus' && (
              <button type="button" className="hint" disabled={loading} onClick={this.showConfirmHint}>
                <FontAwesomeIcon icon={hintLoading ? faSpinner : faLightbulb} spin={hintLoading} />
                Hint
              </button>
              )
            }
          </div>
        )}

        {showConfirmHint && (
          <div className="confirm">
            <h2>Weet je zeker dat je een hint wil vragen?</h2>
            <p>Een hint kost je 15 minuten. Zodra je hieronder op 'JA' klikt sturen we je zo snel mogelijk een passende hint.</p>
            <button type="button" className="no" onClick={this.hideConfirmHint}>Nee</button>
            <button type="button" className="yes" onClick={this.getHint}>Ja</button>
          </div>
        )}

        {showReceivedHint && (
          <div className="confirm">
            <h2>Je hint is onderweg!</h2>
            <p>Een ogenblik geduld nog. We sturen je zo snel mogelijk een hint! We hebben de strafminuten alvast van je tijd afgehaald.</p>
            <button type="button" className="yes" onClick={this.hideReceivedHint}>OK</button>
          </div>
        )}
      </div>
    );
  }
}
