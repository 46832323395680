import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';

export default class Scoreboard extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  formatScore(score) {
    const time = moment.duration(score, 'minutes');
    let duration = time.as('milliseconds');
    let prefix = '+';

    if (duration < 0) {
      prefix = '-';
      duration *= -1;
    }

    if (duration === 0) {
      prefix = '';
    }

    return `${prefix} ${moment.utc(duration).format('HH:mm:ss')}`;
  }

  render() {
    const { teams, scoreboard, uid } = this.props;

    let scores = _.map(_.orderBy(teams, 'name'), (team) => {
      const score = _.get(scoreboard, team.id, 0);
      const time = this.formatScore(score);

      return {
        id: team.id,
        name: team.name,
        finished: team.finished,
        score,
        time,
      };
    });

    scores = _.orderBy(scores, 'score', 'asc');
    let ranking = 0;
    let previousScore = null;
    return (
      <div className="scoreboard">
        <h1>Leaderboard</h1>

        {_.map(scores, (team) => {
          if (team.id === 'ZUlvwNGhA5QdMIoBblvBShJ5xBn2') { return null; }
          if (team.finished) {
            return null;
          }
          let showRanking = '';
          let isSelf = false;
          if (previousScore !== team.score) {
            previousScore = team.score;
            ranking += 1;
            showRanking = `${ranking}.`;
          }
          if (team.id === uid) {
            isSelf = true;
          }
          return (
            <div className="team" key={team.id}>
              <span className="ranking">{showRanking}</span>
              {isSelf && <strong>{team.name} *</strong>}
              {!isSelf && team.name}
            </div>
          );
        })}

        {_.map(scores, (team) => {
          if (team.id === 'ZUlvwNGhA5QdMIoBblvBShJ5xBn2') { return null; }
          if (!team.finished) {
            return null;
          }
          let isSelf = false;
          if (team.id === uid) {
            isSelf = true;
          }
          return (
            <div className="team" key={team.id}>
              <FontAwesomeIcon className="flag" icon={faFlag} />
              {isSelf && <strong>{team.name} *</strong>}
              {!isSelf && team.name}
            </div>
          );
        })}
      </div>
    );
  }
}
