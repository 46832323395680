/* eslint-disable class-methods-use-this */
import React from 'react';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';

const Marker = ({ team }) => <div className="marker"><span>{team}</span></div>;

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: [],
    }
  }

  componentDidMount() {
    this.loadPositions();
  }

  createMapOptions() {
    return {
      mapTypeId: 'roadmap',
      rotateControl: true,
      gestureHandling: 'greedy',
      zoomControl: false,
      backgroundColor: 'hsla(0, 0%, 0%, 0)',
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            { visibility: 'simplified' },
            { saturation: -80 },
          ],
        },
      ],
    };
  }

  loadPositions() {
    const { database } = this.props;

    database.collection('positions').onSnapshot((querySnapshot) => {
      const positions = [];
      querySnapshot.forEach((doc) => {
        const pos = doc.data();
        pos.id = doc.id;
        positions.push(pos);
      });
      this.setState({
        positions,
      });
    });
  }

  render() {
    const { positions } = this.state;
    const { teams } = this.props;

    const locations = _.map(teams, (team) => {
      return _.maxBy(positions, (i) => {
        if (i.teamId  == team.id) {
          return i.timestamp;
        } else {
          return 0;
        }
      });
      // console.log(_.map(_.sortBy(positions, ['timestamp', 'desc']), 'timestamp'));
      return _.find(_.sortBy(positions, ['timestamp', 'desc']), { teamId: team.id });
    });

    return (
      <div style={{ height: '96vh', width: '100%' }}>
        <GoogleMapReact
          options={this.createMapOptions}
          bootstrapURLKeys={{ key: 'AIzaSyBLh5vLoj2gZALNey2aF6onG8etEZbCZc8' }}
          center={{
            lat: 51.557931,
            lng: 5.611115,
          }}
          defaultZoom={11}
        >

          {locations.map((location) => {
            if (!location) { return null; }
            return (
              <Marker
                key={location.id}
                lat={location.lat}
                lng={location.lng}
                team={_.get(_.find(teams, ['id', location.teamId]), 'name')}
              />
            );
          })}

        </GoogleMapReact>
      </div>
    )
  }
}