import React from 'react';

import logo from './images/logo.png';

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamId: null,
      password: null,
      loading: false,
    };

    this.login = this.login.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }

  login() {
    const { auth } = this.props;
    const { teamId, password } = this.state;

    if (!teamId || !password) { return; }
    this.setState({ loading: true });
    auth.signInWithEmailAndPassword(teamId, password).catch((error) => {
      this.setState({ loading: false });
      // // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // // // ...
      console.log("error");
      console.log(errorCode, errorMessage);
    });
  }

  inputChange(e) {
    if (e.target.id === 'teamId' || e.target.id === 'password') {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="auth">
        <img className="logo" src={logo} alt="The Holy Fucking Trail" />

        <div className="login">
          <label htmlFor="teamId">Gebruikersnaam</label>
          <input id="teamId" type="text" onChange={this.inputChange} />
          <label htmlFor="password">Wachtwoord</label>
          <input id="password" type="password" onChange={this.inputChange} />
          <button type="button" onClick={this.login} disabled={loading}>Inloggen</button>
        </div>
      </div>
    );
  }
}
