import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCheck } from '@fortawesome/pro-solid-svg-icons';

export default class Assignments extends React.Component {
  render() {
    const { selectedTrack, assignments, selectAssignment, uid } = this.props;
    const orderedAssignments = _.sortBy(_.filter(assignments, ['trackId', _.get(selectedTrack, 'id')]), 'order');
    const trackAssignments = _.map(orderedAssignments, (assignment, index) => {
      // if (assignment.trackId !== _.get(selectedTrack, 'id')) {
      //   return null;
      // }
      // if (index === orderedAssignments.length - 1) {
      if (_.indexOf(assignment.solved, uid) < 0) {
        return (
          <div className="assignment" key={assignment.order} onClick={() => selectAssignment(assignment)}>
            <div className="order" style={{ backgroundColor: _.get(selectedTrack, 'color') }}>{assignment.order}</div>
            {assignment.title}
            {!assignment.answer && <FontAwesomeIcon className="check" icon={faCheck} />}
          </div>
        );
      }

      return (
        <div className="assignment done" key={assignment.order}>
          <div className="order" style={{ backgroundColor: _.get(selectedTrack, 'color') }}>{assignment.order}</div>
          {assignment.title}
          <FontAwesomeIcon className="check" icon={faCheck} />
        </div>
      );
    });

    if (_.compact(trackAssignments).length < 1) {
      return (
        <div className="assignments description">
          <p>We zijn begonnen!<br /><br />Links vind je de twee tracks, hemel en hel, die allebei een aantal puzzels bevatten om tot de eindlocatie te komen.<br />Klik op een van de iconen om te beginnen. Succes!</p>
        </div>
      );
      
    }

    if (_.get(selectedTrack, 'id') === 'bonus') {
      return (
        <div className="assignments">
          <h1>Bonus opdrachten</h1>
          <p>Minuten winnen? Los deze puzzels op en ontvang 10 bonus-minuten. Als eerste klaar? dan krijg je nog eens 5 minuten extra.<br />Let op: een fout antwoord kost je hier 2 minuten!</p>
          {
            _.map(orderedAssignments, (assignment, index) => {
              if (_.indexOf(assignment.solved, uid) > -1) {
                return (
                  <div className="assignment done" key={assignment.order}>
                    <div className="order"><FontAwesomeIcon icon={faStar} /></div>
                    {assignment.title}
                    <FontAwesomeIcon className="check" icon={faCheck} />
                  </div>
                );
              }

              return (
                <div className="assignment" key={assignment.order} onClick={() => selectAssignment(assignment)}>
                  <div className="order"><FontAwesomeIcon icon={faStar} /></div>
                  {assignment.title}
                  {!assignment.answer < 0 && <FontAwesomeIcon className="check" icon={faCheck} />}
                </div>
              );
            })
          }
        </div>
      );
    }

    return (
      <div className="assignments">
        <h1>Opdrachten</h1>

        {trackAssignments}
      </div>
    );
  }
}
