/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import _ from 'lodash';
import moment from 'moment';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWater, faWind, faTree, faFire, faDotCircle, faStar, faCloud, faAngel, faStars
} from '@fortawesome/pro-solid-svg-icons';

import Admin from './Admin';
import Login from './Login';
import Clock from './Clock';
import Countdown from './Countdown';
import Assignments from './Assignments';
import ShowAssignment from './ShowAssignment';
import Scoreboard from './Scoreboard';
import logo from './images/logo.png';

import './scss/style.scss';


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      teams: [],
      scoreboard: {},
      assignments: [],
      tracks: [],
      loggedIn: false,
      uid: null,
      selectedTrack: null,
      activeAssignment: null,
      pos: null,
    };

    this.AID = '1rjUhZOOT1Us4XRSH4YDaIcWP0X2';
    this.openDate = '2020-01-31T16:00:00';
    library.add(faWater, faWind, faFire, faTree, faDotCircle, faCloud, faAngel, faStars);

    this.selectAssignment = this.selectAssignment.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.requestHint = this.requestHint.bind(this);
  }

  componentDidMount() {
    this.loadFirebase();
    document.addEventListener('contextmenu', event => event.preventDefault());
  }

  bindGeo() {
    const { uid } = this.state;
    if (navigator.geolocation && (uid !== this.AID)) {
      navigator.geolocation.getCurrentPosition(
        this.handleLocationChange.bind(this),
        this.handleLocationError.bind(this),
      );
      navigator.geolocation.watchPosition(
        this.handleLocationChange.bind(this),
        this.handleLocationError.bind(this),
      );
    } else {
      // Browser doesn't support Geolocation
    }
  }

  startAuth() {
    if (window.location.search === '?logout') {
      firebase.auth().signOut().then(() => {
        window.location.href = '/';
      });
    }

    this.setState({
      loaded: true,
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { uid } = user;
        this.setState({
          loggedIn: true,
          uid,
        }, () => {
          this.bindGeo();
          this.loadTeams();
          this.loadTracks();
          this.updateCurrentLocation();
          setInterval(this.updateCurrentLocation.bind(this), 120000);
        });
      } else {
        this.setState({
          loggedIn: false,
        });
      }
    });
  }

  loadFirebase() {
    const firebaseConfig = {
      apiKey: 'AIzaSyCsLBwrWbKFr8kwoUg-cyFNrGXys1g0XaA',
      authDomain: 'thft-bcfb1.firebaseapp.com',
      databaseURL: 'https://thft-bcfb1.firebaseio.com',
      projectId: 'thft-bcfb1',
      storageBucket: 'thft-bcfb1.appspot.com',
      messagingSenderId: '108869746287',
      appId: '1:108869746287:web:4ee5affd82f4037c',
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    this.database = firebase.firestore();
    this.functions = firebase.functions();
    this.startAuth();
  }

  loadTeams() {
    this.database.collection('teams').get().then((snapshots) => {
      const teams = [];
      _.map(snapshots.docs, (doc) => {
        const team = doc.data();
        teams.push({
          name: _.get(team, 'name'),
          // finished: _.get(team, 'finished', false),
          id: doc.id,
        });
      });
      this.setState({
        teams,
      }, () => {
        this.loadScores();
      });
    });
  }

  loadTracks() {
    this.database.collection('tracks').get().then((snapshots) => {
      const tracks = [];
      _.map(snapshots.docs, (doc) => {
        const track = doc.data();
        tracks.push({
          name: _.get(track, 'name'),
          icon: _.get(track, 'icon', 'dot-circle'),
          color: _.get(track, 'color', '#2d2e82'),
          id: doc.id,
        });
      });
      this.setState({
        tracks,
      }, () => {
        this.loadAssignments();
      });
    });
  }

  loadScores() {
    this.database.collection('scores').onSnapshot((querySnapshot) => {
      let scores = [];
      querySnapshot.forEach((doc) => {
        const score = doc.data();
        scores.push(score);
      });
      scores = _.groupBy(scores, score => score.teamId);

      const scoreboard = {};
      _.map(scores, (score) => {
        scoreboard[score[0].teamId] = _.sumBy(score, 'minutes');
      });

      this.setState({
        scoreboard,
      });
    });
  }

  loadAssignments() {
    const { uid } = this.state;
    this.database.collection('assignments').where('teams', 'array-contains', uid).onSnapshot((querySnapshot) => {
      const assignments = [];
      querySnapshot.forEach((doc) => {
        const assignment = doc.data();
        assignment.id = doc.id;
        assignments.push(assignment);
      });

      this.setState({
        assignments,
      });
    });
  }

  selectTrack(selectedTrack) {
    this.setState({ selectedTrack });
  }

  selectAssignment(selectedAssignment) {
    this.setState({ activeAssignment: selectedAssignment });
  }

  submitAnswer(answer, assignmentId, callback) {
    const submitAnswer = this.functions.httpsCallable('submitAnswer');
    submitAnswer({ answer, assignmentId }).then((result) => {
      callback(result);
    });
  }

  requestHint(assignmentId, callback) {
    const { pos } = this.state;
    const requestHint = this.functions.httpsCallable('requestHint');

    requestHint({ assignmentId, lat: pos.lat, lng: pos.lng }).then((result) => {
      callback(result);
    });
  }

  updateCurrentLocation() {
    const { pos } = this.state;
    if (pos === null) { return; }
    const postPosition = this.functions.httpsCallable('postPosition');
    postPosition({ lat: pos.lat, lng: pos.lng }, () => {
    });
  }

  handleLocationChange(position) {
    this.setState({
      pos: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });
  }

  handleLocationError(browserHasGeolocation) {
    console.error(browserHasGeolocation ? 'Error: The Geolocation service failed.' : 'Error: Your browser doesn\'t support geolocation.');
  }

  render() {
    const {
      loggedIn,
      teams,
      scoreboard,
      loaded,
      uid,
      tracks,
      assignments,
      selectedTrack,
      activeAssignment,
    } = this.state;

    const trackColor = _.get(selectedTrack, 'color', '#333');
    const bonusAssignments = _.filter(assignments, ['trackId', 'bonus']);
    const unfinishedBonusAssignments = _.filter(bonusAssignments, o => (_.indexOf(o.solved, uid) < 0)).length;

    if (!loaded) {
      return <div className="loading">Laden...</div>;
    }
    if (!loggedIn || !uid) {
      return <Login auth={firebase.auth()} />;
    }

    if (uid === this.AID) {
      return (
        <div>
          <Admin database={this.database} functions={this.functions} tracks={tracks} />
          <Scoreboard teams={teams} scoreboard={scoreboard} />
          <Clock color={trackColor} />
        </div>
      );
    }
    if (moment().diff(moment(this.openDate)) < 0) {
      return (
        <div>
          <img className="logo" src={logo} alt="The Holy Fucking Trail" style={{ backgroundColor: trackColor }} />
          <Countdown openDate={this.openDate} refresh={() => this.forceUpdate()} />
        </div>
      );
    }

    return (
      <div>
        <img className="logo" src={logo} alt="The Holy Fucking Trail" style={{ backgroundColor: trackColor }} />
        <div className="tracks">
          {_.map(tracks, track => (
            <div key={track.id} className="track" style={track.id === _.get(selectedTrack, 'id') ? { backgroundColor: track.color } : {}} onClick={() => this.selectTrack(track)}>
              <FontAwesomeIcon icon={track.icon} />
            </div>
          ))}

          {bonusAssignments.length > 0 && (
            <div className="track bottom" style={_.get(selectedTrack, 'id') === 'bonus' ? { backgroundColor: '#333' } : {}} onClick={() => this.selectTrack({ id: 'bonus' })}>
              <FontAwesomeIcon icon={faStar} />
              {unfinishedBonusAssignments > 0 && <div className="notification">{unfinishedBonusAssignments}</div>}
            </div>
          )}
        </div>

        <Assignments
          selectedTrack={selectedTrack}
          assignments={assignments}
          selectAssignment={this.selectAssignment}
          uid={uid}
        />
        <Clock color={trackColor} uid={uid} scoreboard={scoreboard} />
        <Scoreboard teams={teams} scoreboard={scoreboard} uid={uid} />
        <ShowAssignment
          assignment={activeAssignment}
          close={this.selectAssignment}
          submit={this.submitAnswer}
          requestHint={this.requestHint}
        />
      </div>
    );
  }
}

export default hot(module)(App);
