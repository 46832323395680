/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable class-methods-use-this */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';

const messagebird = require('messagebird')('6gHkp0tvLv1ku9b0qxSEaaEA7');

const Marker = ({ team }) => <div className="marker"><span>{team}</span></div>;

export default class Hints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hints: [],
      message: {},
    };

    this.sendHint = this.sendHint.bind(this);
    this.updateSMSMessage = this.updateSMSMessage.bind(this);
  }

  componentDidMount() {
    this.loadHintRequests();
  }

  createMapOptions() {
    return {
      mapTypeId: 'roadmap',
      rotateControl: true,
      gestureHandling: 'greedy',
      zoomControl: false,
      backgroundColor: 'hsla(0, 0%, 0%, 0)',
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            { visibility: 'simplified' },
            { saturation: -80 },
          ],
        },
      ],
    };
  }

  loadHintRequests() {
    const { database } = this.props;

    database.collection('hints').onSnapshot((querySnapshot) => {
      let hints = [];
      querySnapshot.forEach((doc) => {
        const hint = doc.data();
        hint.id = doc.id;
        hints.push(hint);
      });
      hints = _.orderBy(hints, 'timestamp', 'desc');
      this.setState({
        hints,
      });
    });
  }

  updateSMSMessage(event) {
    const { value, id } = event.target;
    this.setState({
      message: {
        [id]: value,
      },
    });
  }

  sendHint(hintId, teamId, timestamp) {
    const { phones, database } = this.props;
    const { message } = this.state;
    const recipient = _.find(phones, ['teamId', teamId]);
    const r = confirm('Weet je zeker dat je deze SMS wil versturen?');
    // console.log(hintId, _.get(message, hintId));
    console.log(recipient);
    if (!r) {
      return;
    }
    const restitution = _.clamp(moment().diff(moment(timestamp), 'minutes'), 0, 15);
    console.log(restitution);
    if (restitution > 0) {
      database.collection('/scores').add({
        teamId,
        minutes: restitution * -1,
        reason: 'HINT RESTITUTION',
        timestamp: Date.now(),
      });
    }
    if (!message || message === '') { return; }
    const params = {
      originator: 'THFT',
      recipients: [recipient.number],
      body: `HINT: ${message[hintId]}`,
    };
    messagebird.messages.create(params, (err, response) => {
      if (err) {
        return alert(err);
      }
      this.setState({ message: {} });
      database.collection('/hints').doc(hintId).update({
        sent: message[hintId],
      });
      return console.log(response);
    });
  }

  render() {
    const { hints, message } = this.state;
    const { teams, assignments, phones } = this.props;

    return (
      <div>

      {/* <div>
        {
          _.map(teams, (team) => {
            const requests = _.filter(hints, { teamId: team.id });
            return <div>{team.name} {requests.length}</div>
          })
        }
      </div> */}

        {_.map(hints, (hint) => {
          const assignment = _.find(assignments, ['id', hint.assignmentId]);
          const team = _.find(teams, ['id', hint.teamId]);
          return (
            <div className="hint-request" key={hint.id}>
              <div style={{ height: '22vh', width: '40vw', float: 'right' }}>
                <GoogleMapReact
                  options={this.createMapOptions}
                  bootstrapURLKeys={{ key: 'AIzaSyBLh5vLoj2gZALNey2aF6onG8etEZbCZc8' }}
                  center={{
                    lat: hint.lat,
                    lng: hint.lng,
                  }}
                  defaultZoom={11}
                >
                  <Marker
                    lat={hint.lat}
                    lng={hint.lng}
                    team={_.get(_.find(teams, ['id', hint.teamId]), 'name')}
                  />
                </GoogleMapReact>
              </div>
              <label>Tijd</label><strong>{moment(hint.timestamp).format('HH:mm:ss')}</strong> ( {moment(hint.timestamp).fromNow()} )<br />
              <label>Team</label><strong>{_.get(team, 'name')} ({_.get(_.find(phones, ['teamId', _.get(team, 'id')]), 'number')})</strong><br />
              <label>Opdracht</label><strong>{_.upperCase(_.get(assignment, 'title'))}</strong><br />
              <label>Hint</label><strong>{hint.sent ? hint.sent : '-'}</strong><br />
              {
                !hint.sent && (
                <div>
                  <textarea value={_.get(message, hint.id)} id={hint.id} onChange={this.updateSMSMessage} placeholder="Typ hier de hint" />
                  <button type="button" onClick={() => this.sendHint(hint.id, hint.teamId, hint.timestamp)}>Versturen</button>
                </div>
                )}
            </div>
          );
        })
        }
      </div>
    )
  }
}